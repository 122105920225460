import { globalAPICallback } from './UserPool';
import axios from '../axios';

export const downloadFeedback = claimId => async dispatch =>
  globalAPICallback(async () => {
    const res = await axios.post('/feedback', { currentDate: String(new Date('05/04/2023').getTime()) })
    const headers = {
      roomId: 'RoomId',
      createdOn: "Created On",
      score: "Score",
      comments: "Comments"
    };
    const data = res.data.results.map(e => {
      return {
        roomId: e.roomId,
        createdOn: new Date(parseFloat(e.createdTime)).toLocaleDateString(),
        score: e.score,
        comments: e.comments.replace(/,/g, '')
      }
    });
    console.log(data);
    exportCSVFile(headers, data, 'Feedback');
  });

export const downloadInterfaceReport = (claimId, roomId) => async dispatch =>
  globalAPICallback(async () => {
    return axios({
      method: 'GET',
      responseType: 'blob',
      url: `/claim/${claimId}/room/${roomId}/report?includeAll=True`,
      headers: {
        'Content-Type': 'application/octet-stream',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'PropertyReport.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  });

function convertToCSV(objArray) {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in array[i]) {
      if (line !== '') line += ','

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
}

function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);
  const csv = convertToCSV(jsonObject);
  const exportedFilenmae = fileTitle + '.csv' || 'export.csv';
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

