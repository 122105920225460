export const getTask = (data) => {
  return {
    type: "GETTASK",
    payload: data,
  };
};

export const selectedData = (data, history, status) => {
  if (status === true) {
    history.push({
      pathname: "/app/maincomponent/roomsPreview",
    });
  }
  return {
    type: "SELECTEDDATA",
    payload: data,
  };
};

export const selectedTaskData = (data) => {
  return {
    type: "SelectedTaskData",
    payload: data,
  };
};

export const roomIssue = (data) => {
  return {
    type: "RoomIssue",
    payload: data,
  };
};

export const RoomImageIssue = (data) => {
  return {
    type: "RoomImageIssue",
    payload: data,
  };
};
