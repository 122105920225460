import React from "react";
import PageHeader from "../PageHeader";
import { Container } from "react-bootstrap";
import "./Main.scss";
import RoomsPreview from "../../components/RoomsPreview";
import RoomImage from "../../components/RoomImage";
import RoomImageReordering from "../../components/RoomImageReordering";
import { Route } from "react-router-dom";

const MainComponent = () => {
  return (
    <React.Fragment>
      <PageHeader />
      <Container className="pb-5">
        <Route
          path="/app/maincomponent/roomsPreview"
          component={RoomsPreview}
          exact
        />
        <Route
          path="/app/maincomponent/roomImage"
          component={RoomImage}
          exact
        />
        <Route
          path="/app/maincomponent/roomImageReordering"
          component={RoomImageReordering}
          exact
        />
      </Container>
    </React.Fragment>
  );
};

export default MainComponent;
