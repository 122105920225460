export const CLAIMED = 'claimed';
export const UNCLAIMED = 'unclaimed';
export const PROCEED_TO_IMAGE_QA = 'proceed_to_image_qa';
export const ROOM_REVIEW_PAGE_LOAD = 'room_review_page_load';
export const ROOM_PREVIEW_REJECTED = 'room_preview_rejected';
export const ROOM_PREVIEW_NEXT = 'room_preview_next';
export const ROOM_IMAGES_PAGE_LOAD = 'room_images_page_load';
export const ROOM_IMAGES_NEXT = 'room_images_next';
export const ROOM_IMAGES_REJECTED = 'room_images_rejected';
export const ROOM_IMAGES_IMAGE_COMPLAINTS = 'room_images_image_complaints';
export const ROOM_IMAGES_IMAGE_WARNINGS = 'room_images_image_warnings';
export const ROOM_IMAGE_REORDERING_PAGE_LOAD = 'room_image_reordering_page_load';
export const ROOM_IMAGE_REORDERING_COMPLETE = 'room_image_reordering_complete';
export const GLOBAL_COMPLETION_TIME = 'global_completion_time';
export const ARCHIVE_IMAGE = 'archive_image';
export const UNARCHIVE_IMAGE = 'unarchive_image';
export const ARCHIVE_DAMAGES = 'archive_damages';
export const UNARCHIVE_DAMAGES = 'unarchive_damages';