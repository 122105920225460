import React, {useEffect} from "react";
import "./ImageComplaint.scss";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';
import RoomImg from "../../data/RoomsImage.json";
import roomPreviewData from "../../data/RoomsPreview.json";
import {roomIssue} from "../../store/actions/tasks";
import {useDispatch} from "react-redux";
import {mapErrors} from '../../utils/utils';

const ImageComplaint = ({ data, id, func }) => {
  const [roomIssues, setRoomIssues] = useLocalStorage(id, {});
  const dispatch = useDispatch();
  const roomPreviewIssues = useReadLocalStorage('roomIssues');
  
  useEffect(() => {
    const issues = Object.entries(roomPreviewIssues || {});
    if (issues.length && id === 'image-complaint-0') {
      const errors = mapErrors(issues, roomPreviewData?.roomsPreview?.issues);
      
      setTimeout(() => {
        dispatch(roomIssue(errors));
      }, 100)
    }
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    const issues = Object.entries(roomIssues);
    if (issues.length) {
      const errors = mapErrors(issues, RoomImg.roomImages.informations.imgComplaint);
      setTimeout(() => {
        func(errors, Number(id.split('-')[2]));
      }, 100);
    }
    // eslint-disable-next-line
  }, []);

  const handleSelect = (issue, value) => {
    let temp = { ...roomIssues }
    
    if (value && value !== '') {
      temp[issue] = value
    } else {
      delete temp[issue];
    }

    const errors = mapErrors(Object.entries(temp), data);
    setRoomIssues(temp);
    func(errors, Number(id.split('-')[2]));
  }

  return (
    <>
      <h5>Issues</h5>
      {data.map((issue, index) => {
        if (issue.type === 'boolean') {
          return (
            <FormGroup row>
              <FormControlLabel label={issue.name}
                control={
                  <Switch
                    checked={issue.id in roomIssues}
                    onChange={(evt) => handleSelect(issue.id, evt.target.checked)}
                    name={issue.id}
                  />
                }
              />
            </FormGroup>
          )
        } else if (issue.type === 'text') {
          return (
            <FormGroup row>
              <FormControlLabel label={`No. of ${issue.name}`}
                control={
                  <TextField
                    style={{width: 50}}
                    inputProps={{style:{textAlign: 'center'}}}
                    InputProps={{ type: 'number' }}
                    value={roomIssues[issue.id] || ''}
                    onChange={(evt) => handleSelect(issue.id, evt.target.value)}
                  />
                } />
            </FormGroup>
          )
        } else {
          return null
        }
      })}
    </>
  );
};

export default ImageComplaint;
