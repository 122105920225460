import React, { useCallback, forwardRef, useState } from 'react';
import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    DragOverlay,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Lightbox } from "react-modal-image";
import IconButton from '@mui/material/IconButton';
import ExpandIcon from '@mui/icons-material/ZoomIn';

function SortableGrid({ imageList = [], handleDelete, handleChange }) {
    const [activeId, setActiveId] = React.useState(null);

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor)
    );

    const handleDragStart = useCallback((event) => {
        setActiveId(event.active.id);
    }, []);

    const handleDragEnd = useCallback((event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = imageList.findIndex(e => e.imageId === active.id);
            const newIndex = imageList.findIndex(e => e.imageId === over.id);
            let changedOrder = arrayMove(imageList, oldIndex, newIndex);
            handleChange(changedOrder);
        }
        setActiveId(null);
    }, [imageList, handleChange]);

    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, []);

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragCancel={handleDragCancel}>
            <SortableContext items={imageList} strategy={rectSortingStrategy}>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${4}, 1fr)`,
                        gridGap: 10,
                        margin: '40px auto',
                    }}
                >
                    {imageList.map((item, index) => (
                        <ImageItem key={item?.imageId} id={item?.imageId} item={item} handleDelete={handleDelete} index={index} />
                    ))}
                </div>
            </SortableContext>
            <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
                {activeId ? <ImageItem item={imageList.find(e => e.imageId === activeId) || {}} index={imageList.findIndex(e => e.imageId === activeId) || ''} handleDelete={handleDelete} isDragging /> : null}
            </DragOverlay>
        </DndContext>
    );
}

const ImageItem = forwardRef(({ index, handleDelete, item, ...props }, ref) => {
    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    const [open, setOpen] = useState(false);

    return <div className="image-wrapper-div">
        <p>Wall {index + 1}</p>
        <div key={index} className="image-wrapper-reorder">
            <div className="position-relative">
                <button onClick={() => handleDelete(item)} type="button" className="close close-btn" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                {open && <Lightbox large={item.image} small={item.image} alt="rooms-preview" className="img-fluid" onClose={() => setOpen(false)} />}
                <div ref={setNodeRef}
                    style={style}
                    withOpacity={isDragging}
                    {...props}
                    {...attributes}
                    {...listeners}>
                    <img src={item.image} alt="rooms-preview" className="img-fluid" />
                </div>
            </div>
            <IconButton sx={{float: 'right'}} onClick={() => setOpen(true)}><ExpandIcon /></IconButton>
        </div>
    </div>
})

export default SortableGrid;
