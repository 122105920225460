import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { HashRouter, Route, Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Header from "./components/Header/Header";
import Login from "./pages/Auth/Login/Login";
import Signup from "./pages/Auth/Signup/Signup";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import QADashboard from "./pages/app/AllClaim/AllClaim";
import NotFound from "./pages/app/NotFound/NotFound";
import MainComponent from "./Layouts/Main";
import store from "./store/store";
import mixpanel from 'mixpanel-browser';
import { loadUser } from "./store/actions/auth";
import ModlingDeshboard from "./pages/app/ModlingDashboard/ModlingDashboard";
import { useFlagsmith } from 'flagsmith/react';
import { getTask, selectedData } from "./store/actions/tasks";
import tasksApi from './utils/tasksApi';
import * as Sentry from '@sentry/react';
import LineItems from "./pages/app/LineItems";

const AppRoute = (props) => {
  const { component: Component, ...rest } = props;
  const { noHeader, nonAuthenticated, freeRoute } = rest;
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const tasks = useSelector((state) => state.taskReducer.taskData);
  const flagsmith = useFlagsmith();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_ID_QA || '', {
      debug: true,
      track_pageview: 'full-url',
    }, 'qa');
    mixpanel.init(process.env.REACT_APP_MIXPANEL_ID_CAPTURE || '', {
      debug: true,
    }, 'capture');
    mixpanel.qa.set_config({ persistence: 'localStorage' });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      mixpanel.qa.identify(user.email);
      flagsmith.identify(user.email);
      const organization = user?.['custom:organization'];
      if (organization) {
        mixpanel.qa.register({organization});
        flagsmith.setTrait('organization', organization);
      }
      getData(0, 10);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user && flagsmith.initialised) {
      const organization = user?.['custom:organization'];
      flagsmith.setTrait('organization', organization);
    }
    // eslint-disable-next-line
  }, [user, flagsmith.initialised])

  useEffect(() => {
    if (tasks?.tasks?.length === 1) {
      dispatch(selectedData({ tasks: tasks?.tasks || [] }, history, false));
    }
    // eslint-disable-next-line
  }, [tasks]);

  const getData = async (start, end) => {
    try {
      const { data } = await tasksApi.get('tasks', { params: { start, end } });
      if (data.type === "QA") {
        data.tasks = data.tasks.map(e => {
          return { ...e, status: e.status === 'unclaimed' ? 'claimable' : e.status }
        });
        dispatch(getTask(data));
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <Route
      {...rest}
      render={(renderProps) => {
        const { history } = renderProps;
        if (!freeRoute) {
          if (nonAuthenticated && isAuthenticated) {
            history.push("/app/qadashboard");
            return null;
          }
          if (!nonAuthenticated && !isAuthenticated) {
            history.push("/login");
            return null;
          }
        }
        return (
          <Fragment>
            {!noHeader && <Header />}
            <Component {...rest} {...renderProps} />
          </Fragment>
        );
      }}
    />
  );
};
const Routing = () => {
  const [isRendered, setRendered] = useState(false);

  useEffect(() => {
    (async () => {
      await store.dispatch(loadUser());
      setRendered(true);
    })();
  }, []);

  if (!isRendered) return null;

  return (
    <HashRouter>
      <ScrollToTop scrollDisabledPathname={[]}>
        <Switch>
          <AppRoute
            path="/"
            component={Login}
            exact
            noHeader
            nonAuthenticated
          />
          <AppRoute
            path="/login"
            component={Login}
            exact
            noHeader
            nonAuthenticated
          />
          <AppRoute
            path="/signup"
            component={Signup}
            exact
            noHeader
            nonAuthenticated
          />
          <AppRoute
            path="/forgotpassword"
            component={ForgotPassword}
            exact
            noHeader
            nonAuthenticated
          />

          <AppRoute path="/app/qadashboard" component={QADashboard} exact />
          <AppRoute
            path="/app/maincomponent"
            component={MainComponent}
            noHeader
          />
          <AppRoute
            path="/app/modelingDashboard"
            component={ModlingDeshboard}
            exact
          />
          <AppRoute path="/app/lineitems/:roomId" component={LineItems} exact />
          <Route path="*" exact component={NotFound} />
        </Switch>
      </ScrollToTop>
    </HashRouter>
  );
};
AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
};
export default Routing;
