import React from "react";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { useHistory } from 'react-router-dom';

const ButtonComponent = ({ next, reject, disabledReject=false, disabledNext=false }) => {
  const history = useHistory();
  return (
    <Stack direction="row" spacing={2} justifyContent="end">
        <Button
          onClick={reject}
          variant="contained"
          size="large"
          color="error"
          disabled={disabledReject}
          disableElevation
          style={{
            ...(!disabledReject && {
              color: '#000',
              backgroundColor: '#FAB5A8FF',
            })
          }}
          endIcon={<HighlightOffOutlinedIcon/>}
        >
          Reject
        </Button>
        <Button
          onClick={() => history.goBack()}
          variant="contained"
          size="large"
          disableElevation
          style={{
            backgroundColor: "#d2edb9",
            color: '#000'
          }}
          startIcon={<WestOutlinedIcon/>}
        >
          Prev
        </Button>
        <Button
          onClick={next}
          variant="contained"
          size="large"
          disabled={disabledNext}
          disableElevation
          style={{
            ...(!disabledNext && {
              backgroundColor: "#d2edb9",
              color: '#000'
            })
          }}
          endIcon={<EastOutlinedIcon />}
        >
          Next
        </Button>
    </Stack>
  );
};

export default ButtonComponent;
