import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { resetPasswordWithCode, forgotPassword } from '../../../store/actions/auth';
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
import '../Common.scss';

const ForgotPassword = () => {
  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [modalErrorText, setModalErrorText] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const checkEnter = (e, type) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (type === 1) {
        sendCode();
      }
      if (type === 2) {
        resetPassword();
      }
    }
  };
  const sendCode = async event => {
    // event.preventDefault();
    const emailCheck = /\S+@\S+\.\S+/;
    setErrorEmail(null);
    if (!emailCheck.test(email.trim())) {
      setErrorEmail('Please Enter A Valid Email Address!');
    } else {
      await dispatch(
        forgotPassword({
          email,
        })
      );
      setModalErrorText('A link to reset your password has been sent to your email.');
      setStage(2);
      setModalShown(true);
    }
  };
  const resetPassword = async event => {
    // event.preventDefault();
    const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,40}$/;
    setErrorCode(null);
    setErrorPassword(null);
    setErrorConfirmPassword(null);
    if (code.trim() === '') {
      setErrorCode('Please Enter Code!');
    } else if (!decimal.test(password.trim())) {
      setErrorPassword(
        'Password must be 6 to 15 characters long which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character'
      );
    } else if (!(password === confirmPassword)) {
      setErrorConfirmPassword('Confirm password does not match with password!');
    } else {
      const res = await dispatch(
        resetPasswordWithCode({
          email,
          code,
          password,
        })
      );
      if (res === 'success') {
        setModalErrorText('Your password has been changed successfully.');
        setModalShown(true);
        history.push('/');
      } else {
        setModalErrorText(res);
      }
      setModalShown(true);
    }
  };
  return (
    <div id="forgotpassword" className="main-conatiner">
      <div className="bgImg" />
      <div className="inner-div">
        <div className="main-logo-container">
          <img src="/logo.svg" alt="Logo"/>
        </div>
        {stage === 1 && (
          <div className="floating-input-div">
            <div className="input-container">
              <input
                className="floating-input"
                type="text"
                placeholder="Email"
                value={email}
                onKeyDown={e => checkEnter(e, 1)}
                onChange={event => setEmail(event.target.value)}
              />
              {errorEmail ? (
                <div>
                  <p className="ErrorDivEmail">{errorEmail}</p>
                </div>
              ) : null}
            </div>
            <div className="button">
              <Button className="newButton" onClick={sendCode}>
                Send verification code
              </Button>
            </div>
            <div className="createAcc">
              <p role="none" className="createAccTxt" onClick={history.goBack}>
                Back
              </p>
            </div>
          </div>
        )}
        {stage === 2 && (
          <div className="floating-input-div">
            <div className="input-container">
              <input
                className="floating-input"
                type="text"
                placeholder="6 Digit Code"
                maxLength={6}
                onKeyDown={e => checkEnter(e, 2)}
                value={code}
                onChange={event => setCode(event.target.value)}
              />
              {errorCode ? (
                <div>
                  <p className="ErrorDivEmail">{errorCode}</p>
                </div>
              ) : null}
              <input
                className="floating-input"
                type={passwordShown ? 'text' : 'password'}
                placeholder="Password"
                onKeyDown={e => checkEnter(e, 2)}
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
              {passwordShown ? (
                <FaEyeSlash
                  className="trailIcon"
                  size={30}
                  onClick={() => setPasswordShown(pass => !pass)}
                />
              ) : (
                <FaEye
                  className="trailIcon"
                  size={30}
                  onClick={() => setPasswordShown(pass => !pass)}
                />
              )}
              {errorPassword ? (
                <div>
                  <p className="ErrorDivEmail">{errorPassword}</p>
                </div>
              ) : null}
              <input
                className="floating-input"
                type={confirmPasswordShown ? 'text' : 'password'}
                placeholder="Confirm Password"
                onKeyDown={e => checkEnter(e, 2)}
                onChange={e => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
              {confirmPasswordShown ? (
                <FaEyeSlash
                  className="trailIcon"
                  size={30}
                  onClick={() => setConfirmPasswordShown(pass => !pass)}
                />
              ) : (
                <FaEye
                  className="trailIcon"
                  size={30}
                  onClick={() => setConfirmPasswordShown(pass => !pass)}
                />
              )}
              {errorConfirmPassword ? (
                <div>
                  <p className="ErrorDivEmail">{errorConfirmPassword}</p>
                </div>
              ) : null}
            </div>
            <div className="button">
              <Button className="newButton" onClick={resetPassword}>
                Change password
              </Button>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={modalShown}
        onHide={() => setModalShown(false)}
        centered
        animation
        size="md"
        className="shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3>{modalErrorText}</h3>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div>
            <PrimaryButton
              className="new_claims_btn"
              title="OK"
              onClick={() => setModalShown(false)}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ForgotPassword;
