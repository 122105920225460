import axios from "axios";
import { UserPool } from '../store/actions/UserPool';

const portalApi = axios.create({ baseURL: `https://${process.env.REACT_APP_PORTAL_API_URL}/v1/` });

const getToken = () => {
	return new Promise((resolve, reject) => {
		const user = UserPool.getCurrentUser();
		user?.getSession((err, session) => {
			if (err) {
				reject('');
				return;
			}
			
			resolve(session.idToken.jwtToken);
		});
	});
};

portalApi.interceptors.request.use(
	async config => {
		const token = await getToken();
		config.headers['Authorization'] = token;
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

export default portalApi;