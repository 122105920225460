import React, { useEffect, useState } from "react";
import "./WallView.scss";
import { useSelector } from "react-redux";
import SortableGrid from "./SortableGrid";
import { Row, Col } from "react-bootstrap";
import mixpanel from "mixpanel-browser";
import * as events from "../../utils/logger/events";
import {useFlags} from "flagsmith/react";

const WallView = ({ data, imageChange, setArchiveImgs }) => {
  const selectedTaskValues = useSelector(
    (state) => state?.taskReducer?.SelectedTaskData
  );
  const { select_16_best_imgs } = useFlags(['select_16_best_imgs']);
  const [imgJson, setImgJson] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [archived, setArchived] = useState([]);

  useEffect(() => {
    let missing = imgJson.filter(e => !imageData.map(x => x.imageId).includes(e.imageId));
    missing = missing.filter(e => !archived.includes(e.imageId))
    setImageData([...imageData.filter(e => !archived.includes(e.imageId)), ...missing]);
    setArchiveImgs(archived);
    // eslint-disable-next-line
  }, [archived])

  useEffect(() => {
    let additionalImages_links =
      selectedTaskValues?.additionalImages_links || [];
    let images_links = selectedTaskValues?.images_links || [];

    let imageIds = [];
    selectedTaskValues?.imagesToProcess?.forEach((data) => {
      imageIds.push(data);
    });

    imageIds = Array.from(new Set(imageIds));

    let combineImages = images_links.concat(additionalImages_links);

    let finalImages = [];
    imageIds?.map(imageId => imageId?.split('.')[0]).filter(e => e).forEach(imageId => {
      combineImages?.forEach((image) => {
        if (image?.includes(imageId)) {
          finalImages?.push(image);
        }
      });
    });
    
    let imageJSON = [];
    if (!select_16_best_imgs.enabled) {
      finalImages.forEach((data, index) => {
        if (imageIds[index]) {
          imageJSON.push({id: index, image: data, imageId: imageIds[index]});
        }
      });
    } else {
      imageJSON = (selectedTaskValues?.overviewImages_links || []).map((data, index) =>
        ({id: index, image: data, imageId: selectedTaskValues?.overviewImages[index]})
      )
    }

    setImageData(imageJSON);
    setImgJson(imageJSON);
  }, [selectedTaskValues, select_16_best_imgs.enabled]);

  useEffect(() => {
    imageChange(imageData)
  }, [imageData, imageChange])

  const handleDelete = (item) => {
    mixpanel.qa.track(events.ARCHIVE_IMAGE, { imageId: item.imageId });
    setArchived(archived.concat([item.imageId]))
  }

  const handleAdd = (item) => {
    mixpanel.qa.track(events.UNARCHIVE_IMAGE, { imageId: item.imageId });
    setArchived(archived.filter(e => e !== item.imageId))
  }

  const archivedItemRenderer = (item, index) => {
    return (
      <div className="position-relative">
        <button onClick={() => handleAdd(item)} type="button" className="close ok-btn" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">+</span>
        </button>
        <img src={item.image} alt="rooms-preview" className="img-thumbnail rounded" />
      </div>
    );
  };

  const handleRLDDChange = (reorderedItems) => {
    if (reorderedItems)
      setImageData(reorderedItems);
  };

  return (
    <React.Fragment>
      <Col xs={12}>
        <SortableGrid imageList={imageData} handleDelete={handleDelete} handleChange={handleRLDDChange} />
      </Col>
      {archived.length > 0 && (
        <>
          <Col xs="auto">
            <h4>Archived Images</h4>
          </Col>
          <Col xs={12} className="pb-5">
            <Row fluid={true}>
              {imgJson.filter(e => archived.includes(e.imageId)).map((e, idx) => {
                return (<Col xs={6} lg={3}>{archivedItemRenderer(e, idx)}</Col>)
              })}
            </Row>
          </Col>
        </>
      )}
    </React.Fragment>
  );
};

export default WallView;
