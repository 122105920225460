import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckboxIcon from '@mui/icons-material/CheckCircle';
import { Row, Col } from 'react-bootstrap';
import Divider from '@mui/material/Divider';
import mixpanel from "mixpanel-browser";
import * as events from "../../utils/logger/events";
import {useFlags} from "flagsmith/react";

export default function DamageImageOrdering({ onChage, archivedOverviewImgs }) {
    const selectedTaskValues = useSelector((state) => state?.taskReducer?.SelectedTaskData);

    const [damageInfo, setDamageInfo] = useState({});

    useEffect(() => {
        setDamageInfo(selectedTaskValues?.damageInfo || {})
    }, [selectedTaskValues?.damageInfo])

    useEffect(() => {
        onChage(damageInfo)
    }, [damageInfo, onChage])
    
    const handleChange = (damageId, data) => {
        if (data === true) {
            setDamageInfo({ ...damageInfo, [damageId]: { ...damageInfo[damageId], images: damageInfo[damageId]?.images?.reverse() } })
        } else if (Array.isArray(data)) {
            setDamageInfo((prevState) => ({ ...prevState, [damageId]: { ...prevState[damageId], overviewImages: data, notInScope: false } }))
        } else if (data === 'Archived') {
            setDamageInfo((prevState) => ({...prevState, [damageId]: {...prevState[damageId], archived: true}}))
        } else if (data === 'not-in-scope') {
            setDamageInfo((prevState) => ({...prevState, [damageId]: {...prevState[damageId], notInScope: true, overviewImages:[] }}))
        } else {
            setDamageInfo((prevState) => ({ ...prevState, [damageId]: { ...prevState[damageId], archived: false, notInScope: false } }))
        }
    }

    const damageImages = Object.keys(damageInfo).map(e => {
        return {
            damageId: e, ...damageInfo[e],
            damageImageUrls: getImageUrls(damageInfo[e]?.images, selectedTaskValues?.damageImages_links),
            overviewImageUrls: getImageUrls(damageInfo[e]?.overviewImages, [...selectedTaskValues?.additionalImages_links, ...selectedTaskValues?.images_links])
        }
    })

    return <Col xs={12} lg={12} md={12}>
        <div className="section-title">
            <h2>Damage Images</h2>
        </div>
        <Row className='p-3'>
            {damageImages.map((e, idx) => {
                return (
                    <>
                        {idx !== 0 && <Divider style={{ margin : "50px 0" }} />}
                        <DamageImageList
                          key={idx}
                          damageInfo={e}
                          onChange={(data) => handleChange(e.damageId, data)}
                          archivedOverviewImgs={archivedOverviewImgs}
                        />
                    </>
                )}
            )}
        </Row>
    </Col>
}

function DamageImageList({ damageInfo, onChange, archivedOverviewImgs }) {
    const { not_in_scope_damages } = useFlags(['not_in_scope_damages']);
    const selectedTaskValues = useSelector((state) => state?.taskReducer?.SelectedTaskData);
    const [notInScope, setNotInScope] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);

    const getTitle = (idx) => idx === 0 ? 'Zoomed In' : 'Zoomed Out';

    const handleSelected = (overviewImgs) => {
        const filteredOverviewImgs = overviewImgs.filter((img) => !archivedOverviewImgs.includes(img));
        if (!filteredOverviewImgs.length && !alertOpen && not_in_scope_damages.enabled && !Boolean(damageInfo?.archived)) {
            setAlertOpen(true);
        }
        
        if (!filteredOverviewImgs.length && !not_in_scope_damages.enabled) {
            toggleArchive();
        } else if (filteredOverviewImgs.length && !not_in_scope_damages.enabled && Boolean(damageInfo?.archived)) {
            toggleArchive();
        }
        
        onChange(overviewImgs)
    }

    const changeImageOrder = () => {
        onChange(true)
    }

    const isArchived = Boolean(damageInfo?.archived)
    
    const toggleArchive = () => {
        const mixpanelEvent = isArchived ? events.UNARCHIVE_DAMAGES : events.ARCHIVE_DAMAGES;
        mixpanel.qa.track(mixpanelEvent, { damageId: damageInfo?.damageId });
        onChange(isArchived ? 'Unarchived' : 'Archived');
    };
    
    const toggleNotInScope = () => {
        const filteredOverviewImgs = selectedTaskValues?.overviewImages.filter((img) => !archivedOverviewImgs.includes(img));
        onChange(!notInScope ? 'not-in-scope' : filteredOverviewImgs);
        setNotInScope(!notInScope);
    };
    
    const handleClose = () => {
        setAlertOpen(false);
        toggleNotInScope();
    };
    
    
    return (
      <>
        <Col lg={12}>
            <Row>
                <Col className="d-flex flex-column" style={{ gap: 10 }} sm={12} md={12} lg={6}>
                    <div className="w-100">
                        <p className="text-body-tertiary">Damage Id: {damageInfo?.damageId}</p>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button disabled={isArchived || notInScope} size='small' onClick={changeImageOrder}>Change Image Order</Button>
                            <Button disabled={notInScope} size='small' onClick={toggleArchive}>
                                {isArchived ? 'Unarchive' : 'Archive'}
                            </Button>
                            {not_in_scope_damages.enabled && (
                                <Button
                                  disabled={isArchived}
                                  size="small"
                                  onClick={toggleNotInScope}
                                >
                                    Not In Scope
                                </Button>
                            )}
                        </ButtonGroup>
                    </div>
                    
                    <div className="w-100 d-flex">
                        {damageInfo?.images.map((item, idx) => (
                          <div className="position-relative me-2 w-50" key={idx}>
                            <img src={damageInfo?.damageImageUrls[idx]} alt={item.title} className="w-100"/>
                            <div className="w-100 p-2"
                               style={{bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
                              <p className="text-xs p-1 m-0">{getTitle(idx)}</p>
                            </div>
                         </div>
                        ))}
                    </div>
                </Col>
                <Col sm={12} md={12} lg={6}>
                    <ImageSelection
                      onSelected={handleSelected}
                      archivedOverviewImgs={archivedOverviewImgs}
                      selectedValues={damageInfo?.overviewImages}
                      imageUrls={getImageUrls(selectedTaskValues?.overviewImages, [...selectedTaskValues?.additionalImages_links, ...selectedTaskValues?.images_links, ...selectedTaskValues.overviewImages_links ])}
                      imagesIds={selectedTaskValues?.overviewImages}
                      notInScope={notInScope}
                    />
                </Col>
            </Row>
        </Col>
          <Dialog
            open={alertOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">
                  No overview images selected
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      When no overview images are selected, the damage will be marked as not in scope.
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                      Ok
                  </Button>
              </DialogActions>
          </Dialog>
      </>
    );
}

function SelectedImageList({ images, imageUrls, onSelected, selectedValues = [], archivedOverviewImgs }) {
    const isChecked = (imageId) => {
        return selectedValues.includes(imageId)
    }
    
    const filteredImages = images.filter((img) => !archivedOverviewImgs.includes(img));
    const filteredImagesURLS = imageUrls.filter((img) => {
      return !archivedOverviewImgs.includes(img.split('/').pop().split('.').shift() + '.jpeg')
    });
    
    return (
            <div className="d-flex flex-wrap" style={{ gap: 5 }} >
                {filteredImages.map((item, idx) => (
                    <div className="position-relative" style={{ width: '48%' }} key={item}>
                        <img src={filteredImagesURLS[idx]} alt={item.title} className="w-100" />
                        <div onClick={() => onSelected(item)} style={{
                            position: 'absolute',
                            zIndex: 100,
                            cursor: 'pointer',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: 0
                        }}>
                            {isChecked(item) && (
                                <div style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex'
                                }}>
                                    <CheckboxIcon color="error" style={{ margin: 'auto', display: 'flex' }} fontSize='large' />
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
    );
}

const getImageUrls = (imageIds, imageUrls) => {
    return imageIds.map(e => {
        let imageId = e.split('.');
        return imageUrls.find(x => x.includes(imageId[0])) || '/assets/rooms/Reject.svg'
    }).filter(e => e)
}

const ImageSelection = ({ selectedValues = [], imagesIds, imageUrls, onSelected, notInScope, archivedOverviewImgs }) => {
    const handleSelected = (imageId) => {
        if (selectedValues.includes(imageId)) {
            onSelected(selectedValues.filter(e => e !== imageId));
        } else {
            onSelected(selectedValues.concat([imageId]));
        }
    }
    
    return (
      <div className="w-100">
          <p id="simple-dialog-title">Select Overview Images</p>
          {(selectedValues.length < 1 && !notInScope) && (<span>Atleast one overview image is required.</span>)}
          <SelectedImageList
            archivedOverviewImgs={archivedOverviewImgs}
            imageUrls={imageUrls}
            images={imagesIds}
            selectedValues={selectedValues}
            onSelected={handleSelected}
          />
      </div>
    );
};
