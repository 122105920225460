import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import QualityCheck from "../../../images/QualityCheck.svg";
import QualityCheck2 from "../../../images/QualityCheck2.svg";
import accesslink1 from "../../../images/accesslink1.svg";
import upload from "../../../images/upload.svg";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineSortDescending } from "react-icons/hi";
import { Form, Overlay, Pagination, Popover } from "react-bootstrap";
import { setActiveClaimPage } from "../../../store/actions/pagination";
import supportingimg from "../../../images/Imageplaceholder.png";
import { TiTick } from "react-icons/ti";
import { IoFileTrayStackedSharp } from "react-icons/io5";
import Popup from "../../../popup";
import Popup2 from "../../../popup2";
import { useHistory } from "react-router-dom";
import { getTask, selectedData } from "../../../store/actions/tasks";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import urls from "../../../env";
import { FaCommentAlt } from "react-icons/fa";
import CrossIcon from "../../../assets/icons/icon-cross.svg";

const allQAErrors = [
  "Missing Item",
  "Extra Item",
  "Wrong Item",
  "Wrong Item measurement",
  "Wrong Wall measurement",
  "Inconsistent Label",
  "Not Enough information",
  "Basic Structure not modeled",
  "Basic Items not modeled",
  "Multiple rooms",
  "Other",
];
function ModlingDeshboard() {
  const history = useHistory();
  const formdata = new FormData();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const activePagination = useSelector(
    (state) => state.pagination.activeClaimPage
  );

  const [loadingScreen, setLoadingScreen] = useState(true);

  const [founderror, setFounderror] = useState(false);
  const [founderror2, setFounderror2] = useState(null);
  const [founderror3, setFounderror3] = useState(null);
  const [approved, setapproved] = useState(null);
  const [ifapprove, setIfapprove] = useState(true);
  const [startd, setStartd] = useState(0);
  const [endd, setEndd] = useState(10);
  const [lastIndex, setLastIndex] = useState(10);
  const [DATA, setDATA] = useState(null);
  const [Erroname,] = useState("");
  const [Data, setData] = useState(DATA);
  const [claimpopup, setClaimpopup] = useState(false);
  const [claimpopupFailed, setClaimpopupFailed] = useState(false);
  const [claimedErrorText, setClaimedErrorText] = useState("");
  const [errordata, setErrordata] = useState(null);
  const [comment, setComment] = useState(null);
  const [errorpopup, setErrorpopup] = useState(false);
  const [popupdata,] = useState(null);
  const [afterPostErrorSuccess, setafterPostErrorSuccess] = useState(false);
  const [afterPostErrorFail, setafterPostErrorFail] = useState(false);
  const [Iconpopup, setIconpopup] = useState(false);
  const [IconId,] = useState(null);
  const [, setShowmyData] = useState(false);

  const [showinputfile, setShowinputfile] = useState(false);
  const [showinputdata, setShowinputdata] = useState([]);
  const [responseurldata, setResponseurldata] = useState("");

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [selectedItemId, setselectedItemId] = useState();
  const [selectedItemData, setselectedItemData] = useState();
  const [activeQaErrors, setActiveQaErrors] = useState(null);
  const [errorPostMessage, setErrorPostMessage] = useState("");

  const [showComments, setShowComments] = useState(false);
  const [commentsTarget, setCommentsTarget] = useState(null);
  const [commentsId, setCommentsId] = useState();
  const [commentsClaimId, setCommentsClaimId] = useState();
  const [commentsRoomId, setCommentsRoomId] = useState();
  const [notes, setNotes] = useState("");

  const getTASKDATA = useSelector((state) => state.taskReducer.taskData);

  useEffect(() => {
    console.log(Math.floor(Number(getTASKDATA?.numTasks / 10)) + 1);
    if (getTASKDATA?.numTasks % 10 === 0)
      setLastIndex(Math.floor(Number(getTASKDATA?.numTasks / 10)));
    else setLastIndex(Math.floor(Number(getTASKDATA?.numTasks / 10)) + 1);
  }, [getTASKDATA?.numTasks]);

  const togglePopup = (data) => {
    setErrordata(data);
    console.log("MYDATA", data);
    setFounderror(!founderror);
  };
  
  const togglePopup2 = (data) => {
    setFounderror2(data);
  };
  const togglePopup3 = (data) => {
    setFounderror3(data);
  };
  const postErrorSuccess = (data) => {
    setafterPostErrorSuccess(true);
  };
  const postErrorError = (data) => {
    setafterPostErrorFail(true);
  };
  const togglePopupforapprove = async (data1) => {
    setapproved(data1);
    let a;
    for (let i = 0; i < data1?.reporterrors?.length; i++) {
      if (data1.reporterrors[i].icon === false) {
        setIfapprove(false);
        a = false;
        break;
      } else {
        setIfapprove(true);
        a = true;
      }
    }
    if (a === true) {
      let index = Data?.indexOf(data1);
      Data?.splice(index, 1);
      setData(Data);
    }
  };

  const handlePagination = async (page) => {
    if (activePagination !== page) {
      const into10 = (page - 1) * 10;
      setStartd(into10);
      setEndd(into10 + 10);
      dispatch(setActiveClaimPage(page));
    }
  };
  
  useEffect(() => {
    getData(startd, endd);
    // eslint-disable-next-line
  }, [startd, endd]);
  
  useEffect(() => {
    if (getTASKDATA) {
      setDATA(getTASKDATA.tasks);
    }
  }, [getTASKDATA]);
  
  const getData = async (start, end) => {
    setLoadingScreen(true);
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Authorization", user.token);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${urls.ApiUrl}?start=${start}&end=${end}&userId=${user.token}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.type === "QA") {
          history.replace("/app/qadashboard");
          setLoadingScreen(false);
        } else {
          dispatch(getTask(result));
          setLoadingScreen(false);
        }
      })
      .catch((error) => {
        setLoadingScreen(false);
      });
  };

  const claimeUser = (id, name, data) => {
    let temp = {
      tasks: [data],
    };
    dispatch(selectedData(temp, history, false));
    setShowmyData(true);
    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", user.token);
    const raw = JSON.stringify({
      "x-api-key": "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66",
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${urls.ApiUrl}/${id}?status=${name}&userId=${user.token}`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status) {
          getData(startd, endd);
          setClaimpopup(!claimpopup);
        } else {
          setClaimedErrorText(result.message);
          setClaimpopupFailed(!claimpopupFailed);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      let temp = showinputdata;
      await formdata.append("key", temp["key"]);
      await formdata.append("x-amz-algorithm", temp["x-amz-algorithm"]);
      await formdata.append("x-amz-credential", temp["x-amz-credential"]);
      await formdata.append("x-amz-date", temp["x-amz-date"]);
      await formdata.append(
        "x-amz-security-token",
        temp["x-amz-security-token"]
      );
      await formdata.append("policy", temp["policy"]);
      await formdata.append("x-amz-signature", temp["x-amz-signature"]);
      await formdata.append("file", acceptedFiles[0]);
      await uploadfileser(formdata);
      // eslint-disable-next-line
    }, [showinputdata, formdata]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadser = (claimId, roomid, id, linktype) => {
    setLoadingUpdate(true);
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Authorization", user.token);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${urls.ApiUrl}/${id}?roomId=${roomid}&claimId=${claimId}&linkType=${linktype}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((blob) => {
        console.log(blob.response_url);
        setResponseurldata(blob.response_url);
        setShowinputdata(blob.fields);
        setShowinputfile(true);
        setLoadingUpdate(false);
      })
      .catch((error) => {
        setLoadingUpdate(false);
      });
  };
  const uploadfileser = (data) => {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("content-type", "multipart/form-data");
    myHeaders.append("Authorization", user.token);

    axios
      .post(`${responseurldata}`, data, myHeaders)
      .then((response) => response.json())
      .then((blob) => {
        console.log(blob);
        claimeUser(selectedItemId, "complete", selectedItemData);
        setShowinputfile(false);
      })
      .catch((error) => {
        console.log("error", error);
        claimeUser(selectedItemId, "complete", selectedItemData);
        setShowinputfile(false);
      });
  };
  const showMyData = () => {
    setClaimpopupFailed(!claimpopupFailed);
  };
  const downloadLink = (roomid, id, claimId, linktype, data) => {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Authorization", user.token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${urls.ApiUrl}/${id}?claimId=${claimId}&roomId=${roomid}&linkType=${linktype}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((blob) => {
        console.log(blob["linkToPortal:"]);

        if (blob["linkToPortal:"] !== undefined && blob["linkToPortal:"] !== "") {
          window.open(blob["linkToPortal:"]);
        } else {
          window.open(blob.link);
        }
        claimeUser(id, "inprogress", data);
      })
      .catch((error) => console.log("error", error));
  };
  const postError = (data) => {
    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", user.token);

    let errors = [];
    activeQaErrors?.each((cur) => {
      errors.push(cur);
    });
    const raw = JSON.stringify({
      errors: errors,
      comments: comment ? comment : "",
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${urls.ApiUrl}/${data}/errors`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          setActiveQaErrors(null);
          postErrorError();
          setFounderror(false);
          setErrorPostMessage(result.message);
        } else {
          setErrorPostMessage(result["message:"]);
          getData(startd, endd);
          setComment(null);
          setFounderror(false);
          postErrorSuccess();
          setActiveQaErrors(null);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setActiveQaErrors(null);
      });
  };

  const postComment = (data) => {
    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", user.token);

    const raw = JSON.stringify({
      claimId: commentsClaimId,
      roomId: commentsRoomId,
      comments: notes ? notes : "",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${urls.ApiUrl}/${data}/errors`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        getData(startd, endd);
      }).catch((error) => {
        console.log("error", error);
      });
  };

  const textareaInput = (event) => {
    const value = event.target.value;
    setComment(value);
  };

  const selectErrors = (data, comment = "", status) => {
    allQAErrors?.each((error) => {
      if (data === error) {
        if (status === true) {
          let Errors = [];
          activeQaErrors
            ?.filter((cur) => Object.keys(cur)[0] !== data)
            .each((current) => {
              Errors.push(current);
            });
          setActiveQaErrors(Errors);
        } else {
          if (
            activeQaErrors?.filter((cur) => Object.keys(cur)[0] === data)
              .length === 0
          ) {
            setActiveQaErrors([...activeQaErrors, { [data]: comment }]);
          } else {
            let dta = [];
            activeQaErrors
              ?.filter((cur) => Object.keys(cur)[0] !== data)
              .each((current) => {
                dta.push(current);
              });
            setActiveQaErrors([...dta, { [data]: comment }]);
          }
        }
      }
    });
  };

  const handleComments = (e, data) => {
    setNotes(data.comments);
    setCommentsId(data.id);
    setCommentsClaimId(data.claimId);
    setCommentsRoomId(data.roomId);
    setShowComments(!showComments);
    setCommentsTarget(e.target);
  };

  return (
    <div className="main">
      {loadingScreen ? (
        <p></p>
      ) : DATA?.length !== 0 ? (
        <div className="contain">
          <div className="searchbox">
            <div className="search-text-main"></div>
          </div>
          <div className="table">
            <table>
              <tr>
                <th>Priority</th>
                <th style={{ whiteSpace: "pre-line" }}>
                  <div className="thcontent">
                    <div>
                      <p>Time Sent</p>
                    </div>
                  </div>
                </th>
                <th style={{ whiteSpace: "pre-line" }}>
                  <div className="thcontent">
                    <div>
                      <p>Time Left</p>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="thcontent">
                    <div className="thcontent1">
                      <HiOutlineSortDescending
                        className="sorticon"
                        onClick={() => {}}
                      />
                    </div>
                    <div className="thcontent2">
                      <p>Status</p>
                    </div>
                  </div>
                </th>
                <th className="npspacforheaderModling">Download</th>
                <th className="npspacforheaderModling">Upload</th>
                <th className="npspacforheaderModling">Comment</th>
                <th className="npspacforheaderModling">Room Id</th>
              </tr>

              {/* MODLING DASHBOARD */}
              {DATA?.map((data, i) => {
                if (data && data.status && data.roomId && data.roomId !== "") {
                  return (
                    <tr className="bordertr">
                      <td className="npspacModling">
                        {data.priority}
                      </td>
                      <td>
                        {new Date(
                          Number(data.timeSent * 1000)
                        ).toLocaleString()}
                      </td>
                      {data.timeLeft !== "" ? (
                        <td>{data.timeLeft}</td>
                      ) : (
                        <td></td>
                      )}
                      {data.status !== "" ? <td>{data.status}</td> : <td></td>}
                      <td className="accesslinkModling">
                        <img
                          alt="downloadInstruction"
                          onClick={() => {
                            downloadLink(
                              data.roomId,
                              data.id,
                              data.claimId,
                              "downloadInstruction",
                              data
                            );
                          }}
                          src={accesslink1 || ''}
                        />
                      </td>
                      <td className="npspacModling">
                        <img
                          alt="npspacimgnpspacModling"
                          className="npspacimgnpspacModling"
                          src={upload || ''}
                          onClick={() => {
                            uploadser(
                              data.claimId,
                              data.roomId,
                              data.id,
                              "upload"
                            );
                            setselectedItemId(data.id);
                            setselectedItemData(data);
                          }}
                        />
                        {loadingUpdate === true &&
                        selectedItemData?.id === data.id ? (
                          <p>Loading...</p>
                        ) : null}
                      </td>
                      <td className="npspacModling">
                        <FaCommentAlt
                          className="commentIconModling"
                          onClick={(e) => {
                            handleComments(e, data);
                          }}
                        />
                      </td>
                      {data.roomId !== "" ? <td>{data.roomId}</td> : <td></td>}
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </table>
            {claimpopup !== false && (
              <Popup2
                content={
                  <div className="resloved lessWidthPopup">
                    <>
                      <div className="closeresolved">
                        <AiOutlineClose
                          onClick={() => {
                            setClaimpopup(!claimpopup);
                          }}
                          className="closeicon"
                        />
                      </div>
                      <div className="contentresolved">
                        <h5>Thank you for Updating Status</h5>
                      </div>
                    </>
                  </div>
                }
                handleClose={togglePopupforapprove}
              />
            )}
            {claimpopupFailed !== false && (
              <Popup2
                content={
                  <div className="resloved lessWidthPopup">
                    <>
                      <div className="closeresolved">
                        <AiOutlineClose
                          onClick={showMyData}
                          className="closeicon"
                        />
                      </div>
                      <div className="contentresolved">
                        <h5>{claimedErrorText}</h5>
                      </div>
                    </>
                  </div>
                }
                handleClose={togglePopupforapprove}
              />
            )}
            {founderror !== false && (
              <Popup
                content={DATA?.filter((data) => errordata.id === data.id).map(
                  (allerr) => {
                    return (
                      <div>
                        <div className="popupcontent">
                          <div className="selecterrors foundErrorsTable">
                            <h5>Please Indicate Errors You Found </h5>
                            <p>Please check one Or multiple that applies</p>
                            <table>
                              <tr>
                                <th>Error Type</th>
                                <th>Description</th>
                              </tr>
                              {allQAErrors?.map((cur) => {
                                if (
                                  activeQaErrors?.filter(
                                    (fil) => Object.keys(fil)[0] === cur
                                  ).length > 0
                                ) {
                                  let key = "";
                                  activeQaErrors
                                    ?.filter(
                                      (fil) => Object.keys(fil)[0] === cur
                                    )
                                    .map((cur1) => {
                                      return cur1[Object.keys(cur1)[0]];
                                    });
                                  return (
                                    <tr>
                                      <td>
                                        <img
                                          alt="QA check"
                                          onClick={() =>
                                            selectErrors(cur, "", true)
                                          }
                                          style={{ width: "20px" }}
                                          className="additemicons"
                                          src={QualityCheck || ''}
                                        />{" "}
                                        <span>{cur}</span>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={key}
                                          onChange={(e) => {
                                            selectErrors(
                                              cur,
                                              e.target.value,
                                              false
                                            );
                                          }}
                                          placeholder="Enter Description"
                                        />
                                      </td>
                                    </tr>
                                  );
                                } else {
                                  return (
                                    <tr>
                                      <td>
                                        <img
                                          alt="additemicons"
                                          onClick={() =>
                                            selectErrors(cur, "", false)
                                          }
                                          style={{ width: "20px" }}
                                          className="additemicons"
                                          src={QualityCheck2}
                                        />{" "}
                                        <span>{cur}</span>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </table>
                          </div>
                          <div className="addcomments">
                            <div>
                              <AiOutlineClose
                                onClick={() => {
                                  togglePopup(!founderror);
                                  setActiveQaErrors(null);
                                }}
                                className="closeicon"
                              />
                            </div>
                            <div>
                              <textarea
                                onChange={
                                  (e) => {
                                    setComment(e.target.value);
                                  }
                                }
                                value={comment}
                                placeholder="Add comments"
                                rows="12"
                                cols="49 "
                              ></textarea>
                            </div>
                            <div>
                              <button
                                onClick={() => {
                                  postError(allerr.id);
                                }}
                                className="savebutton"
                              >
                                SAVE
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              />
            )}
            {Iconpopup !== false && (
              <Popup
                classerror={Erroname}
                content={
                  <div className="resloved ShowErrorPopup">
                    {Erroname === "Qa" ? (
                      IconId ? (
                        <div style={{ textAlign: "left", width: "70%" }}>
                          <h2>{Erroname} Errors</h2>
                          <table>
                            <tr>
                              <th>Error Type</th>
                              <th>Description</th>
                            </tr>
                            {allQAErrors?.map((cur) => {
                              if (
                                IconId?.errors?.filter(
                                  (fil) => Object.keys(fil)[0] === cur
                                ).length > 0
                              ) {
                                return (
                                  <tr>
                                    <td>
                                      <img
                                        alt="additemicons"
                                        style={{ width: "20px" }}
                                        className="additemicons"
                                        src={QualityCheck || ''}
                                      />{" "}
                                      <span>{cur}</span>
                                    </td>
                                    <td>
                                      {IconId?.errors
                                        ?.filter(
                                          (fil) => Object.keys(fil)[0] === cur
                                        )
                                        .map((cur1) => {
                                          return <span>{cur1[`${cur}`]}</span>;
                                        })}
                                    </td>
                                  </tr>
                                );
                              } else {
                                return (
                                  <tr>
                                    <td>
                                      <img
                                        alt="QualityCheck2"
                                        style={{ width: "20px" }}
                                        className="additemicons"
                                        src={QualityCheck2 || ''}
                                      />{" "}
                                      <span>{cur}</span>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </table>
                        </div>
                      ) : (
                        <div style={{ textAlign: "left" }}>
                          <h2>Errors</h2>
                          {allQAErrors.map((cur) => {
                            return (
                              <div style={{ margin: "7px", textAlign: "left" }}>
                                <img
                                  alt="additemicons"
                                  style={{ width: "20px" }}
                                  className="additemicons"
                                  src={QualityCheck2 || ''}
                                />{" "}
                                <span>{cur}</span>
                                <p>{cur}</p>
                              </div>
                            );
                          })}
                        </div>
                      )
                    ) : IconId ? (
                      <div style={{ textAlign: "left", width: "70%" }}>
                        <h2>{Erroname} Errors</h2>
                        <table>
                          <tr>
                            <th>Error Type</th>
                            <th>Description</th>
                          </tr>
                          {IconId?.errors?.map((cur) => {
                            return (
                              <tr>
                                <td>
                                  <img
                                    alt="additemicons"
                                    style={{ width: "20px" }}
                                    className="additemicons"
                                    src={QualityCheck || ''}
                                  />
                                  <span> {cur.type}</span>
                                </td>
                                <td>
                                  <span>{cur.comments}</span>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    ) : (
                      <div style={{ textAlign: "left" }}>
                        <h2>Errors</h2>
                        {IconId?.errors?.map((cur) => {
                          return (
                            <div style={{ margin: "7px", textAlign: "left" }}>
                              <img
                                alt="additemicons"
                                style={{ width: "20px" }}
                                className="additemicons"
                                src={QualityCheck2 || ''}
                              />{" "}
                              <span>{cur.type}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <div className="addcomments">
                      <div>
                        <AiOutlineClose
                          onClick={() => {
                            setIconpopup(!Iconpopup);
                          }}
                          className="closeicon"
                        />
                      </div>
                      <div>
                        <textarea
                          value={
                            IconId?.comments !== ""
                              ? IconId?.comments
                              : "no comments posted"
                          }
                          placeholder="Add comments"
                          disabled
                          rows="12"
                          cols="49 "
                        ></textarea>
                      </div>
                    </div>
                  </div>
                }
                handleClose={togglePopupforapprove}
              />
            )}
            {afterPostErrorSuccess !== false && (
              <Popup2
                content={
                  <div className="resloved">
                    <>
                      <div className="closeresolved">
                        <AiOutlineClose
                          onClick={() => {
                            setafterPostErrorSuccess(!afterPostErrorSuccess);
                            setFounderror(false);
                          }}
                          className="closeicon"
                        />
                      </div>
                      <div className="contentresolved">
                        <h5>{errorPostMessage}</h5>
                      </div>
                    </>
                  </div>
                }
                handleClose={togglePopupforapprove}
              />
            )}
            {afterPostErrorFail !== false && (
              <Popup2
                content={
                  <div className="resloved">
                    <>
                      <div className="closeresolved">
                        <AiOutlineClose
                          onClick={() => {
                            setafterPostErrorFail(!afterPostErrorFail);
                            setFounderror(false);
                          }}
                          className="closeicon"
                        />
                      </div>
                      <div className="contentresolved">
                        <h5>{errorPostMessage}</h5>
                      </div>
                    </>
                  </div>
                }
                handleClose={togglePopupforapprove}
              />
            )}
            {founderror2 !== null && (
              <Popup
                content={
                  <div className="founderror2">
                    <div>
                      <AiOutlineClose
                        onClick={() => togglePopup2(null)}
                        className="closeicon2"
                      />
                    </div>
                    <div className="missingtitle">
                      <div style={{ width: "50%" }}>
                        <h5>Missing Item</h5>
                      </div>
                    </div>
                    <div className="missingcontent">
                      <div className="missingcontentrow1">
                        <h4>Comment by 3D vender</h4>
                        <hr />
                        <p>Label is missing the refrigerator</p>
                      </div>
                      <div className="missingcontentrow2">
                        <h4>Supporting images</h4>
                        <hr />
                        <div className="supportingimages">
                          <img alt="supporting images" src={supportingimg || ''} />
                          <img alt="supporting images" src={supportingimg || ''} />
                          <img alt="supporting images" src={supportingimg || ''} />
                          <img alt="supporting images" src={supportingimg || ''} />
                          <img alt="supporting images" src={supportingimg || ''} />
                          <img alt="supporting images" src={supportingimg || ''} />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
            )}
            {founderror3 !== null && (
              <Popup
                content={
                  <div className="popupcontent">
                    <div className="selecterrors">
                      <p>
                        Which of the folloing issues have you resolved by
                        uploading{" "}
                      </p>
                      <p style={{ fontSize: "15px" }}>
                        Please check one Or multiple that applies
                      </p>
                      <ul>
                        <li>
                          <img alt="additemicons" className="additemicons" src={QualityCheck || ''} />
                          Inconsistent Number
                        </li>
                      </ul>
                    </div>
                    <div className="addcomments">
                      <div>
                        <AiOutlineClose
                          onClick={() => togglePopup3(null)}
                          className="closeicon"
                        />
                      </div>
                      <div>
                        <p>Please check or comment what you fixed</p>
                      </div>
                      <div className="checkicons">
                        <div className="checkiconcontent">
                          <TiTick className="checkicon" />
                          &nbsp; Corrected CSV
                        </div>
                        <div className="checkiconcontent">
                          <TiTick />
                          &nbsp; Change Materials
                        </div>
                        <div className="checkiconcontent">
                          <TiTick />
                          &nbsp; Remodel items
                        </div>

                        <div className="checkiconcontent">
                          <TiTick />
                          &nbsp; Resend to labeling
                        </div>
                        <div className="checkiconcontent">
                          <TiTick />
                          &nbsp; Renumber items
                        </div>
                        <div className="checkiconcontent">
                          <TiTick />
                          &nbsp; Adjust measurement
                        </div>
                      </div>
                      <div>
                        <textarea
                          placeholder="Add comments"
                          rows="08"
                          cols="49 "
                        ></textarea>
                      </div>
                      <div className="resolvebutton">
                        <button className="savebutton">RESOLVE ISSUE</button>
                      </div>
                    </div>
                  </div>
                }
              />
            )}
            {errorpopup !== false && (
              <Popup
                content={DATA.map((data) => {
                  return data.reportedErrors
                    .filter((data) => data === popupdata)
                    .map((data) => (
                      <div className="popupcontent">
                        <div className="selecterrors">
                          <h5>Please Indicate Errors You Found </h5>
                          <p>Please check one Or multiple that applies</p>
                          <ul>
                            <li>
                              <img
                                alt="additemicons"
                                className="additemicons"
                                src={QualityCheck || ''}
                              />
                              Inconsistent Number
                            </li>
                            <li>{(data.type1, console.log(data.type1))}</li>
                          </ul>
                        </div>
                        <div className="addcomments">
                          <div>
                            <AiOutlineClose
                              onClick={() => setErrorpopup(!errorpopup)}
                              className="closeicon"
                            />
                          </div>
                          <div>
                            <textarea
                              onChange={textareaInput}
                              placeholder="Add comments"
                              rows="12"
                              cols="49 "
                            ></textarea>
                          </div>
                          <div>
                            <button
                              onClick={() => {
                                // postError(allerr)
                              }}
                              className="savebutton"
                            >
                              SAVE
                            </button>
                          </div>
                        </div>
                      </div>
                    ));
                })}
              />
            )}
            {approved !== null && (
              <Popup
                content={
                  <div className="resloved">
                    {ifapprove === false ? (
                      <>
                        <div className="closeresolved">
                          <AiOutlineClose
                            onClick={() => {
                              setapproved(null);
                            }}
                            className="closeicon"
                          />
                        </div>
                        <div className="contentresolved">
                          <h5>
                            You Can't approve unless all issues has been
                            resolved
                          </h5>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="closeresolved">
                          <AiOutlineClose
                            onClick={() => {
                              setapproved(null);
                            }}
                            className="closeicon"
                          />
                        </div>
                        <div className="contentresolved">
                          <h5>Your issues has been approved</h5>
                        </div>
                      </>
                    )}
                  </div>
                }
                handleClose={togglePopupforapprove}
              />
            )}
          </div>
          {lastIndex > 1 ? (
            <div id="ClaimsPagination">
              <Pagination>
                <Pagination.First
                  active={false}
                  onClick={() => handlePagination(1)}
                />
                {/* <Pagination.Prev
                active={false}
                onClick={() => handlePagination(activePagination - 1)}
              /> */}
                {[...Array(lastIndex)]?.map?.((i, number) => (
                  <Pagination.Item
                    key={`allcalim_paginate_${number + 1}`}
                    onClick={() => handlePagination(number + 1)}
                    active={number + 1 === activePagination}
                  >
                    {number + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Last
                  active={false}
                  onClick={() => handlePagination(lastIndex)}
                />
              </Pagination>
            </div>
          ) : null}
          {showinputfile === true && (
            <Popup
              content={
                <div className="resloved">
                  <>
                    <div className="closeresolved">
                      <AiOutlineClose
                        onClick={() => {
                          setShowinputfile(false);
                        }}
                        className="closeicon"
                      />
                    </div>
                    <div className="contentresolved">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <IoFileTrayStackedSharp className="dropzoneIcon" />
                        {isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <p>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                </div>
              }
              handleClose={togglePopupforapprove}
            />
          )}

          {/* comment Overlay */}
          {showComments && <div className="overlayBackground"></div>}
          <Overlay
            show={showComments}
            target={commentsTarget}
            key={commentsTarget?.key}
            placement="bottom"
            containerPadding={20}
          >
            <Popover className="p-2" id="comments-popover">
              <div
                className="py-3 ps-1 filter__title  border-bottom-0 d-flex justify-content-between"
              >
                Add comments
                <img
                  alt="cross icon"
                  src={CrossIcon || ''}
                  className="d-flex justify-content-end"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowComments(false);
                  }}
                />
              </div>
              <div className="p-0">
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      rows={6}
                      className="text-area-css"
                      defaultValue={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <div className="popover__footer d-flex justify-content-between my-3">
                    <button
                      className="clear__filter"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowComments(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="apply__filter"
                      onClick={(e) => {
                        e.preventDefault();
                        postComment(commentsId);
                        setShowComments(false);
                      }}
                    >
                      Save
                    </button>
                  </div>
                </Form>
              </div>
            </Popover>
          </Overlay>
        </div>
      ) : (
        <div className="noclaimdata">
          <div className="searchbox">
            <div className="search-text-main"></div>
          </div>
          <div className="table">
            <table>
              <tr>
                <span>
                  There are no claimable tasks available. Please check later
                </span>
              </tr>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
export default ModlingDeshboard;
