import React from "react";
import { Provider } from "react-redux";
import Routing from "./Routing";
import "./styles/style.scss";
import flagsmith from 'flagsmith'
import {FlagsmithProvider} from 'flagsmith/react'
import * as Sentry from "@sentry/react";

import store from "./store/store";

const App = () => (
  <FlagsmithProvider
    flagsmith={flagsmith}
    options={{
      environmentID: process.env.REACT_APP_FLAGSMITH_ID,
    }}
  >
    <Provider store={store}>
      <Routing />
    </Provider>
  </FlagsmithProvider>
);
export default Sentry.withProfiler(App);
