import React, { Fragment, useEffect, useState } from "react"
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import Slide from '@mui/material/Slide';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CheckIcon from "@mui/icons-material/Check";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import UpIcon from "@mui/icons-material/ArrowUpward";
import DownIcon from "@mui/icons-material/ArrowDownward";
import Edit from "@mui/icons-material/Edit";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete from "@mui/material/Autocomplete";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const getCategories = (ruleInfo, otherLineItems = []) => {
    const categories = Object.keys(ruleInfo).map(x => {
        return ruleInfo[x]?.map(e => {
            return e.line_items?.concat(otherLineItems).map(f => f.category)
        })
    }).flat(2)
    return Array.from(new Set(categories))
}

export const getSelectors = (category, ruleInfo, otherLineItems = []) => {
    const selectors = Object.keys(ruleInfo).map(x => {
        return ruleInfo[x]?.map(e => {
            return e.line_items?.concat(otherLineItems).filter(f => f.category === category).map(f => f.selector)
        })
    }).flat(2)
    return Array.from(new Set(selectors))
}

export const getActions = (ruleInfo, otherLineItems = []) => {
    return Array.from(new Set(Object.keys(ruleInfo).map(x => {
        return ruleInfo[x]?.map(e => {
            return e.line_items?.concat(otherLineItems).map(f => f.action || f.activity)
        })
    }).flat(2)))
}

export const getDescription = (ruleInfo, params, otherLineItems = []) => {
    return Object.keys(ruleInfo).map(x => {
        return ruleInfo[x]?.map(e => {
            const lines = e.line_items?.concat(otherLineItems).filter(f => f?.category === params.row?.category && params.row.selector === f.selector && params.row.action === f.action)
            return lines.map(f => { return f.description || 'NA' })
        })
    }).flat(2).filter(e => Boolean(e) && e !== 'NA')?.[0] || (params?.row?.selector?.length > 2 ? params.row.selector : 'Not a Valid LineItem')
}

export const getRule = (rules, row) => {
    for (let i = 0; i < rules?.length; i++) {
        let f = rules[i];
        if (f?.category === row?.category && row?.selector === f.selector && (row?.action === f.action || row?.activity === f.action)) {
            return f.rule
        }
    }
    return 'NA';
}

export default function AddRule({ rules, open, onClose, onSave }) {
    const [selected, setSelected] = useState([]);
    const [expanded, setExpanded] = useState('ceiling');
    const [distinct, setDistinct] = useState([]);
    const [notifiers, setNotifiers] = useState([]);

    const handleClose = () => {
        setDistinct([]);
        onClose();
        setSelected([]);
        setExpanded('ceiling');
    }

    const handleSave = () => {
        onSave({
            distinct: distinct.map(x => {
                return { ...x, sortId: undefined }
            }), selected, notifiers
        })
        handleClose()
    }

    const addLineItems = (newRules) => {
        const allRules = Object.keys(rules).map(e => {
            return rules[e]
        }).flat();
        let map = {};
        let notify = [];
        distinct.forEach(x => {
            map[`${x.category}##${x.selector}##${x.action}`] = x
        })
        newRules.forEach(e => {
            allRules.find(x => x.name === e)?.line_items?.forEach(x => {
                x['rules'] = [e];
                map[`${x.category}##${x.selector}##${x.action}`] = x;
            })
            const newNotify = allRules.find(x => x.name === e)?.application_notifiers;
            notify = notify.concat(newNotify);
        })
        setNotifiers(Array.from(new Set([...notifiers, ...notify])))
        setDistinct(Object.keys(map).map((x, idx) => { return { ...map[x], id: x, calculation: '', sortId: idx } }));
    }

    const handleAddItem = () => {
        setDistinct(distinct.concat({ category: '', action: '', selector: '', calculation: '', id: 'new_line_item', description: '' }))
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSelect = (ruleName) => {
        setSelected(selected.includes(ruleName) ? selected.filter(e => e !== ruleName) : selected.concat(ruleName))
        addLineItems([ruleName]);
        setExpanded(false);
    }

    const handleRuleChange = (field, value, index, evt) => {
        const idx = distinct.findIndex(e => e.id === index);
        const newData = [...distinct];
        newData[idx][field] = value
        newData[idx]['id'] = `${newData[idx].category || ' '}##${newData[idx].selector || ''}##${newData[idx].action || ''}`
        setDistinct(newData);
    }

    const handleDelete = (id) => {
        setDistinct(distinct.filter(e => e.id !== id))
    }

    const isDisabled = () => {
        return !distinct.every(e => {
            const desc = getDescription(rules, { row: { ...e, description: undefined } });
            return Boolean(e.category) && Boolean(e.selector) && Boolean(e.action) && Boolean(e.calculation) && desc !== 'Not a Valid LineItem'
        }) || distinct.length < 1
    }

    const columns = [
        {
            field: 'category',
            headerName: 'Category',
            flex: 1,
            renderCell: (params) => {
                return <SelectControl options={getCategories(rules)} value={params.row.category}
                    label={'Category'}
                    required
                    onChange={(evt) => handleRuleChange('category', evt.target.value, params.row.id)}
                />
            }
        },
        {
            field: 'selector',
            headerName: 'Selector',
            flex: 1,
            renderCell: (params) => {
                return <SelectControl options={getSelectors(params.row.category, rules)} value={params.row.selector}
                    label={'Selector'}
                    required
                    allowAdd
                    onChange={(evt) => handleRuleChange('selector', evt.target.value, params.row.id)}
                />
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => {
                return <SelectControl options={getActions(rules)}
                    value={params.row.action}
                    label={'Action'}
                    required
                    onChange={(evt) => handleRuleChange('action', evt.target.value, params.row.id)}
                />
            }
        },
        {
            field: 'calculation',
            headerName: 'Calculation',
            flex: 1,
            renderCell: (params) => {
                return <TextField sx={{ verticalAlign: 'middle' }}
                    value={params.row.calculation || ''}
                    label='Calculation' size="small"
                    required
                    onChange={(evt) => handleRuleChange('calculation', evt.target.value, params.row.id)}
                    error={!Boolean(params.row.calculation)} />
            }
        },
        {
            field: 'notes',
            headerName: 'Notes',
            flex: 1.5,
            renderCell: (params) => {
                return <NotesControl
                    value={params.row.notes || ''}
                    label='Notes' size="small"
                    onChange={(evt) => handleRuleChange('notes', evt.target.value, params.row.id)}
                />
            }
        },
        {
            field: 'rules',
            headerName: 'Rules',
            flex: 1,
            renderCell: (params) => {
                return <NotesControl
                    value={params.row.rules || ''}
                    label='Rules' size="small"
                    onChange={(evt) => handleRuleChange('rules', evt.target.value, params.row.id)}
                    isEdit={false}
                />
            }
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            renderCell: (params) => {
                const desc = getDescription(rules, params);
                const color = desc === 'Not a Valid LineItem' ? 'error' : 'default'
                return <Tooltip color={color} title={desc}>
                    <InfoIcon />
                </Tooltip >
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            getActions: (params) => {
                return [
                    <GridActionsCellItem icon={<UpIcon />} disabled={params.row?.sortId === 0} label="Move Up" onClick={() => handleSortUp(params.row.sortId)} />,
                    <GridActionsCellItem icon={<DownIcon />} disabled={params.row?.sortId === distinct.length - 1} label="Move Down" onClick={() => handleSortDown(params.row.sortId)} />,
                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => handleDelete(params.id)} />,
                ]
            }
        }
    ];


    const handleSortUp = (id) => {
        const prevItem = distinct.find(e => e.sortId === id - 1);
        const currItem = distinct.find(e => e.sortId === id);
        const temp = prevItem?.sortId
        if (prevItem && (prevItem?.sortId || prevItem?.sortId === 0) && currItem && currItem?.sortId) {
            prevItem.sortId = currItem?.sortId
            currItem.sortId = temp;
            const newLineItems = [...distinct];
            newLineItems.sort((a, b) => {
                return a.sortId - b.sortId;
            })
            setDistinct(newLineItems);
        }
    }

    const handleSortDown = (id) => {
        const nextItem = distinct.find(e => e.sortId === id + 1);
        const currItem = distinct.find(e => e.sortId === id);
        const temp = nextItem?.sortId
        if (currItem && (currItem?.sortId || currItem?.sortId === 0) && nextItem && nextItem?.sortId) {
            nextItem.sortId = currItem?.sortId
            currItem.sortId = temp;
            const newLineItems = [...distinct];
            newLineItems.sort((a, b) => {
                return a.sortId - b.sortId;
            })
            setDistinct(newLineItems);
        }
    }

    const EditToolBar = () => {
        return (
            <GridToolbarContainer>
                <Button startIcon={<AddIcon />} onClick={handleAddItem}>
                    Add Item
                </Button>
            </GridToolbarContainer>
        );
    }

    return <Dialog fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
    >
        <AppBar color="transparent" sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Add Rule(s)
                </Typography>
                <Button autoFocus color="inherit" onClick={handleSave} disabled={isDisabled()}>
                    save
                </Button>
            </Toolbar>
        </AppBar>
        <div style={{ padding: 20 }}>
            {Object.keys(rules).map(e => {
                return <Accordion key={e} expanded={expanded === e} onChange={handleChange(e)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {e}
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
                            {rules[e].map(x => {
                                return <Button key={x.name} endIcon={selected.includes(x.name) ? <CheckIcon /> : null} onClick={() => handleSelect(x.name)} size="small" variant="outlined">{x.name}</Button>
                            })}
                        </div>
                    </AccordionDetails>
                </Accordion>
            })}
            <hr />
            <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                <div style={{ display: 'flex', gap: 5 }}>
                    {selected.map(e => {
                        return <Button key={e} onClick={() => setSelected(selected.filter(x => x !== e))} endIcon={<CloseIcon />} variant="outlined" size="small">{e}</Button>
                    })}
                </div>
                {new Set(distinct.map(e => e.id)).size !== distinct.length && <Alert severity="warning">
                    Please correct the highlighted duplicate line items. If the change is intended, please ignore.
                </Alert>}
                <div style={{ height: 500 }}>
                    <DataGrid
                        rows={distinct}
                        columns={columns.map(e => {
                            return { ...e, minWidth: 200 }
                        })}
                        disableRowSelectionOnClick
                        rowHeight={60}
                        slots={{
                            toolbar: EditToolBar,
                        }}
                        getRowClassName={(params) => {
                            return distinct.filter(e => e.id === params.row.id).length > 1 ? 'duplicate-line-item' : undefined
                        }}
                    />
                </div>
            </div>
        </div>
    </Dialog>
}


export const SelectControl = ({ onChange, label, value, options = [], disabled, required, allowAdd = false }) => {
    const [currentOptions, setCurrentOptions] = useState(options);

    useEffect(() => {
        let results = [...options];
        if (!results.includes(value))
            results.push(value);
        setCurrentOptions(results)
    }, [options, value])

    const handleInputChange = (event, newInputValue) => {
        if (newInputValue) {
            if (!currentOptions.includes(newInputValue)) {
                setCurrentOptions([...currentOptions, newInputValue]);
            }
            onChange({ target: { value: newInputValue } });
        }
    };

    return (
        <FormControl size="small" sx={{ verticalAlign: 'middle' }} fullWidth required={required}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    handleInputChange(event, newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    if (event?.type === 'change' && !currentOptions.includes(newInputValue) && allowAdd) {
                        setCurrentOptions([...currentOptions, newInputValue]);
                    }
                }}
                size="small"
                options={currentOptions}
                sx={{
                    '& .MuiAutocomplete-root': {
                        display: 'flex',
                        alignItems: 'center'
                    },
                    display: 'flex',
                    alignItems: 'center',
                    verticalAlign: 'middle'
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        error={!Boolean(value)}
                        disabled={disabled}
                        required={required}
                        size="small"
                    />
                )}
            />
        </FormControl>
    );
};


export const NotesControl = ({ onChange, label, value, isEdit = true }) => {
    const [open, setOpen] = useState(false);

    return <Fragment>
        <div style={{ display: 'flex' }}>
            {isEdit && <IconButton sx={{ width: 50 }} size="small" onClick={() => setOpen(true)}><Edit /></IconButton>}
            <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{value || 'NA'}</p>
        </div>
        <Dialog maxWidth='md' fullWidth open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                {label}
            </DialogTitle>
            <DialogContent>
                <TextField fullWidth multiline variant="standard" onChange={onChange} label={label} value={value} />
            </DialogContent>
        </Dialog>
    </Fragment>
}