import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { logout } from '../../store/actions/auth';
import { downloadFeedback } from '../../store/actions/downloads';
import { useFlagsmith } from 'flagsmith/react';

const Header = () => {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const flagsmith = useFlagsmith();
  
  const handleLogout = async () => {
    await flagsmith.logout();
    await dispatch(logout());
  };

  const handleFeedback = async () => {
    await dispatch(downloadFeedback());
  }

  return (
    <div id="header">
      <Navbar variant="dark" className="header_navbar d-flex justify-content-end">
        <Nav className="ml-auto" id="header-dropdown">
          <NavDropdown title={user ? user.email : 'Username'} id="username-dropdown">
            <NavDropdown.Item onClick={handleFeedback}>Download Feedback</NavDropdown.Item>
            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
          </NavDropdown>
          <FiChevronDown className="username_arrow" color="white" size={18} />
        </Nav>
      </Navbar>
      <div className="header_logo--block">
        <div className="hexagon">
          <img
            className="header_logo"
            role="none"
            src="/logo.png"
            alt="icon"
            onClick={() => {
              history.push('/app/qadashboard');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
