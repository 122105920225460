import React, { useEffect, useState } from "react";
import "./RoomsPreview.scss";
import RoomData from "../../data/RoomsPreview.json";
import ImagePreview from "../ImagePreview";
import { Row, Col } from "react-bootstrap";
import RoomsIssue from "../RoomsIssue";
import ButtonComponent from "../ButtonComponent";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { roomIssue, selectedTaskData } from "../../store/actions/tasks";
import mixpanel from "mixpanel-browser";
import * as events from '../../utils/logger/events';
import tasksApi from '../../utils/tasksApi';
import portalApi from "../../utils/portalApi";
import * as Sentry from '@sentry/react';
import {useReadLocalStorage} from 'usehooks-ts';
import {logGlobalCompletionTime, clearCachedItems} from  '../../utils/utils';
import {useFlags} from "flagsmith/react";
import toast, { Toaster } from 'react-hot-toast';

const RoomsPreview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const roomIssueData = useSelector(
    (state) => state.taskReducer.roomIssueState
  );
  const GETMYDATA = useSelector(
    (state) => state?.taskReducer?.myData?.tasks
  )?.[0] || [];
  const { select_16_best_imgs } = useFlags(['select_16_best_imgs']);
  const TASKDATA = useSelector((state) => state?.taskReducer?.taskData);
  const user = useSelector((state) => state.auth);
  const [isDamage, setIsDamage] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [pageSideTitle, setPageSideTitle] = useState("");
  const [roomIssues, setRoomIssues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [damageImageData, setDamageImageData] = useState([]);
  const startTime = useReadLocalStorage('start-time');
  
  useEffect(() => {
    dispatch(roomIssue([]));
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    if (GETMYDATA?.roomId && GETMYDATA?.claimId) {
      setLoading(true);
      mixpanel.qa.track(events.ROOM_REVIEW_PAGE_LOAD, {
        roomId:GETMYDATA?.roomId,
        claimId: GETMYDATA?.claimId
      });

      (async () => {
        try {
          const { data } = await portalApi.get(`claim/${GETMYDATA?.claimId}/room/${GETMYDATA?.roomId}`);
          setLoading(false);
          dispatch(selectedTaskData(data));
          
          let additionalImages_links = data?.additionalImages_links || [];
          let images_links = data?.images_links || [];
          
          let combineImages = images_links.concat(additionalImages_links);
          
          let imageIds = [];
          
          data?.imagesToProcess?.forEach((data) => {
            imageIds.push(data.substr(0, data.lastIndexOf(".")));
          });
          
          let finalImages = [];
          imageIds?.forEach((imageId) => {
            combineImages?.forEach((image) => {
              if (image?.includes(imageId)) {
                finalImages?.push(image);
              }
            });
          });
          
          
          setIsDamage(false);
          setDamageImageData(data?.damageImages_links);
          
          if (finalImages?.length > 0 && !select_16_best_imgs.enabled) {
            setImageData(finalImages);
          } else {
            setImageData(data?.overviewImages_links);
          }
        } catch (e) {
          setImageData(null);
          Sentry.captureException(e);
        }
      })();
    }
    // eslint-disable-next-line
  }, [GETMYDATA]);

  useEffect(() => {
    setPageTitle(RoomData.roomsPreview.pageTitle);
    setPageSideTitle(RoomData.roomsPreview.pageSideTitle);
    setRoomIssues(RoomData.roomsPreview.issues);
    // eslint-disable-next-line
  }, []);

  const statusCall = async (status) => {
    const {data} = await tasksApi.post(`tasks/${GETMYDATA?.id}?status=${status}&userId=${user.token}`, {});
    if (data.status) {
      dispatch(roomIssue([]));
      history.push("/app/qadashboard");
    } else {
      alert("facing Some Issue / Please try again later");
    }
  };

  const postError = async () => {
    try {
      const errors_image_qa = {
        image_errors: roomIssueData.map(e => {
          return { error: e }
        })
      };
      
      if (!errors_image_qa.image_errors.length) {
        toast.error('Please select at least one error for the room.');
        return;
      }
      
      const { data } = await tasksApi.post(`tasks/${GETMYDATA?.id}/errors`, { errors_image_qa });
      if (data.message === "Success") {
        await statusCall("failed");
        logGlobalCompletionTime({
          startTime,
          roomId:GETMYDATA?.roomId,
          claimId: GETMYDATA?.claimId,
          closeEvent: events.ROOM_PREVIEW_REJECTED,
        });
        mixpanel.qa.track(events.ROOM_PREVIEW_REJECTED, {
          roomId:GETMYDATA?.roomId,
          claimId: GETMYDATA?.claimId,
          errors: roomIssueData,
        });
        mixpanel.capture.track(events.ROOM_PREVIEW_REJECTED, {
          roomId:GETMYDATA?.roomId,
          claimId: GETMYDATA?.claimId,
          errors: roomIssueData,
        });
        clearCachedItems();
      } else {
        toast.error("facing Some Issue / Please try again later");
      }
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Unexpected error occurred. Please try again later.');
    }
  };
  
  // Exclude the missing walls issue
  const missingWalls = roomIssueData?.filter((issue) => issue === roomIssues?.[0]?.message);
  const filteredIssues = roomIssueData?.filter((issue) => issue !== roomIssues?.[0]?.message);

  return (
    TASKDATA && (
      <React.Fragment>
        <Toaster position="top-right" />
        {imageData != null ? (
          <div className="image-preview my-5">
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="section-title">
                  <h2>{pageTitle}</h2>
                </div>
                <div className="image-previews">
                  <Row className="p-3">
                    <ImagePreview data={imageData} loading={loading} damages={damageImageData} />
                  </Row>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} className="mt-3  mt-lg-0">
                <div className="section-title">
                  <h2>{pageSideTitle}</h2>
                </div>
                <div className="issues-radio">
                  <RoomsIssue data={roomIssues.filter(e => !isDamage ? e.category !== 'damage' : true)} />
                </div>
              </Col>
            </Row>
            <div className="decision-section">
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <ButtonComponent
                    disabledNext={filteredIssues.length}
                    disabledReject={(!filteredIssues.length && missingWalls.length) || !filteredIssues.length}
                    next={() => {
                      mixpanel.qa.track(events.ROOM_PREVIEW_NEXT, {
                        roomId:GETMYDATA?.roomId,
                        claimId: GETMYDATA?.claimId
                      });
                      history.push("/app/maincomponent/roomImage");
                    }}
                    reject={postError}
                  />
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div className="image-preview my-5">
            <Row>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="section-title">
                  <h2>Data Not Present</h2>
                </div>
                <div className="image-previews my-0 pb-5">
                  <Row className="p-3">
                    <p>
                      Images are not available for this claim / please try some
                      other{" "}
                    </p>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </React.Fragment>
    )
  );
};

export default RoomsPreview;
