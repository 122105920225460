import mixpanel from "mixpanel-browser";
import * as events from './logger/events';

export const isArrEmpty = arr => !(Array.isArray(arr) && arr.length);

export const logGlobalCompletionTime = ({startTime, roomId, claimId, closeEvent}) => {
	const completionTime = (Date.now() - startTime) / 1000;
	mixpanel.qa.track(events.GLOBAL_COMPLETION_TIME, {
		completionTime,
		roomId,
		claimId,
		closeEvent
	});
};

export const mapErrors = (issues, data) => {
	const errors = [];
	issues.forEach(([key, value]) => {
		const item = data.find(e => Number(e.id) === Number(key));
		if (item) {
			errors.push(item?.message || (item?.type === 'boolean' ? item?.issue : item?.name + ' ' + value))
		}
	});
	return errors;
};

export const clearCachedItems = () => {
	for (const key of Object.keys(localStorage)) {
		if (key.includes('image-complaint-') || ['roomIssues', 'start-time'].includes(key)){
			localStorage.removeItem(key);
		}
	}
};