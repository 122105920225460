const initialAuthState = {
  isAuthenticated: null,
  token: null,
  user: null,
};

const AuthReducer = (state = initialAuthState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'GET_PROFILE': {
      const auth = { ...payload };
      delete auth.token;

      return {
        ...state,
        isAuthenticated: true,
        token: payload.token,
        user: auth,
      };
    }
    case 'NEW_USER':
    case 'LOGOUT':
      return initialAuthState;
    default:
      return state;
  }
};

export default AuthReducer;
