const intialState = {
  taskData: [],
  myData: [],
  tempData: [],
  SelectedTaskData: {},
  roomIssueState: [],
  RoomImageIssue: [],
};
const taskReducer = (state = intialState, action) => {
  switch (action.type) {
    case "GETTASK":
      return {
        ...state,
        taskData: action.payload,
      };
    case "SELECTEDDATA":
      return {
        ...state,
        myData: action.payload,
      };
    case "TEMPDATA":
      return {
        ...state,
        tempData: action.payload,
      };

    case "SelectedTaskData":
      return {
        ...state,
        SelectedTaskData: action.payload,
      };

    case "RoomIssue":
      return {
        ...state,
        roomIssueState: action.payload,
      };

    case "RoomImageIssue":
      return {
        ...state,
        RoomImageIssue: action.payload,
      };

    default:
      return state;
  }
};
export default taskReducer;
