import "./popup2.css"
import React from "react";
const Popup2 = props => {
    return (
        <div
            className={`popup-box ${props.classerror}` }>
            <div className="popup2">
                {props.content}
            </div>
        </div>
    );
};
export default Popup2;