import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./Header.scss";

const PageHeader = () => {
  const myData = useSelector((state) => state?.taskReducer?.myData);
  return (
    myData?.tasks?.length > 0 ? (
        <Container className="header-content" fluid={true}>
          <Row xs="auto">
            <Col className="column" xs={12} sm={12} lg={6}>
              <div className="header-title">
                <h1 className="mb-0">
                  Room Details Page :{" "}
                  <span className="highlighted-text">
                    Room ID {myData?.tasks[0]?.roomId}
                  </span>
                </h1>
              </div>
            </Col>
            <Col className="column" xs={12} sm={12} lg={3}>
              <div className="duration">
                <h4 className="mb-0">
                  <strong>Type:</strong>{" "}
                  {myData?.tasks[0]?.type}
                </h4>
              </div>
            </Col>
            <Col className="column" xs={12} sm={12} lg={3}>
              <div className="duration">
                <h4 className="mb-0">
                  <strong>Time Left:</strong>{" "}
                  {myData?.tasks[0]?.timeLeft}
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
    ) : null
  );
};

export default PageHeader;
