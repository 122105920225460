import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchComponent({ onSubmit }) {

    const [text, setText] = React.useState('');

    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit(text);
            }}
        >
            <InputBase
                value={text}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search for Room ID"
                onChange={(e) => setText(e.target.value)}
            />
            <IconButton onClick={() => onSubmit(text)} type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}
