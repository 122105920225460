import React, { useState } from "react";
import "./Complaint.scss";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

const Complaint = ({ data, func, id }) => {
  const [roomIssues, setRoomIssues] = useState({});
  
  const handleSelect = (issue, value) => {
    let temp = { ...roomIssues }

    if (value && value !== '') {
      temp[issue] = value
    }
    else
      delete temp[issue];

    let errors = [];
    Object.entries(temp).forEach(([key, value]) => {
      let item = data.find(e => Number(e.id) === Number(key));
      if (item)
        errors.push(item?.message || (item?.type === 'boolean' ? item?.name : item?.name + ' ' + value))
    })
    setRoomIssues(temp);
    func(errors, id);
  }

  return (
    <>
      <h5>Warnings</h5>
      {data.map((issue) => {
        if (issue.type === 'boolean')
          return (
            <FormGroup row>
              <FormControlLabel label={issue.name}
                control={
                  <Switch size="small" checked={issue.id in roomIssues} onChange={(evt) => handleSelect(issue.id, evt.target.checked)} name={issue.id} />
                } />
            </FormGroup>
          )
        if (issue.type === 'text')
          return <FormGroup row>
            <FormControlLabel label={`No. of ${issue.name}`}
              control={
                <TextField
                  variant="standard"
                  inputProps={{style:{textAlign: 'center'}}}
                  style={{width: 50}}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ type: 'number' }}
                  value={roomIssues[issue.id] || ''}
                  onChange={(evt) => handleSelect(issue.id, evt.target.value)}
                />
              } />
          </FormGroup>
        
        return null;
      })}
    </>
  );
};

export default Complaint;