import React, {useEffect} from "react";
import "./RoomsIssues.scss";
import {useDispatch} from "react-redux";
import { roomIssue } from "../../store/actions/tasks";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { useLocalStorage } from 'usehooks-ts';
import roomPreviewData from "../../data/RoomsPreview.json";
import {mapErrors} from '../../utils/utils';


const RoomsIssue = ({ data }) => {
  const [roomIssues, setRoomIssues] = useLocalStorage('roomIssues', {});
  const dispatch = useDispatch();
  
  useEffect(() => {
    const issues = Object.entries(roomIssues);
    if (issues.length) {
      const errors = mapErrors(issues, roomPreviewData?.roomsPreview?.issues);

      setTimeout(() => {
        dispatch(roomIssue(errors));
      }, 100)
    }
    // eslint-disable-next-line
  }, []);

  const handleSelect = (issue, value) => {
    const temp = { ...roomIssues };
    if (value && (value !== '' && value !== '0')) {
      temp[issue] = value
    } else {
      delete temp[issue];
    }

    const errors = mapErrors(Object.entries(temp), data);
    setRoomIssues(temp);
    dispatch(roomIssue(errors));
  }

  return (
    <>
      {data.map((issue) => {
        if (issue.type === 'boolean') {
          return (
            <FormGroup row>
              <FormControlLabel
                label={issue.name}
                control={
                  <Switch
                    checked={issue.id in roomIssues}
                    onChange={(evt) => handleSelect(issue.id, evt.target.checked)}
                    name={issue.id}
                  />
                }
              />
            </FormGroup>
          )
        } if (issue.type === 'text') {
          return <FormGroup row>
            <FormControlLabel
              label={`No. of ${issue.name}`}
              control={
                <TextField
                  min="1"
                  variant="standard"
                  style={{width: 60}}
                  inputProps={{style: {textAlign: 'center'}}}
                  InputProps={{type: 'number', inputProps: { min: 1 }}}
                  value={roomIssues[issue.id] || ''}
                  onKeyDown={(event) => {
                    if (event?.key === '-' || event?.key === '+' || event?.key === '0') {
                      event.preventDefault();
                    }
                  }}
                  onChange={(evt) => {
                    handleSelect(issue.id, evt.target.value);
                  }}
                />
              }
            />
          </FormGroup>
        } else {
          return null
        }
      })}
    </>
  );
};

export default RoomsIssue;