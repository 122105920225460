import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers/reducer";

const middleware = [thunk];
export const store = createStore(
  reducer,
  applyMiddleware(...middleware)
);
export default store;
