import React, {useState} from "react";
import Spinner from 'react-bootstrap/Spinner';

import "./ImagePreview.scss";
import Grid from '@mui/material/Grid';
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import {Modal} from "react-bootstrap";

const ImagePreview = ({ data, loading, damages = [] }) => {
  const [selectedPhotosIndex, setselectedPhotosIndex] = useState(0);
  const [zoomImage, setzoomImage] = useState(false);
  const [imageType, setImageType] = useState('overview');
  
  const imgs = imageType === 'overview' ? data : damages;
  
  const onNextPhotos = () => {
    setselectedPhotosIndex((selectedPhotosIndex + 1) % imgs?.length);
  };
  const onPrevPhotos = () => {
    setselectedPhotosIndex(Math.abs(selectedPhotosIndex - 1) % imgs?.length);
  };
  
  return (
    <React.Fragment>
      {loading ? <Spinner className="my-5" animation="border" /> :
        <Grid container spacing={2}>
          {data.map((imageData, index) => (
            <Grid item xs={3} key={imageData}>
              <img
                src={imageData}
                alt="rooms-preview"
                onClick={() => {
                  setImageType('overview');
                  setselectedPhotosIndex(index);
                  setzoomImage(true);
                }}
                style={{width: '100%', cursor: "pointer"}}
              />
            </Grid>
          ))}
        </Grid>}
      <Grid container spacing={2}>
        <Grid item xs={12}>{damages.length > 0 && <h5>Damage Images</h5>}</Grid>
        {damages.map((imageData, index) => (
          <Grid item xs={3} key={imageData}>
            <img
              src={imageData}
              alt="damage-preview"
              onClick={() => {
                setImageType('damage');
                setselectedPhotosIndex(index);
                setzoomImage(true);
              }}
              style={{width: '100%', cursor: "pointer"}}
            />
          </Grid>
        ))}
      </Grid>
      
      <Modal
        onHide={() => {
          setzoomImage(false);
        }}
        contentClassName="preview-img-modal modelzoomeffect"
        show={zoomImage}
        centered
        animation
        size="lg"
        className="shadow-xl"
      >
        <div onClick={onPrevPhotos} className="arrow arrow-left">
          <FiChevronLeft className="arrow-icon" color="#ffffff" />
        </div>
        <img alt="carousel" src={imgs[selectedPhotosIndex]} width="100%" />
        <div onClick={onNextPhotos} className="arrow arrow-right">
          <FiChevronRight className="arrow-icon" color="#ffffff" />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ImagePreview;
