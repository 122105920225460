import React from 'react';

const MustContainItem = props => {
  // eslint-disable-next-line react/prop-types
  const { data } = props;
  const label = data[0];
  const meetsReq = data[1];

  const setClass = () => {
    const classArr = ['must-text'];
    if (meetsReq) classArr.push('cross-out');
    return classArr.join(' ');
  };

  return (
    <div className="MustContainItem">
      <div className="must-item">
        <li className={setClass()}>{label}</li>
      </div>
    </div>
  );
};

export default MustContainItem;
